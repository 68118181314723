#login-page, #signup-page, #support-page {
  .login-logo {
    padding: 20px 0;
    min-height: 200px;
    text-align: center;
  }
  
  .login-logo img {
    max-width: 150px;
  }
  
  .list {
    margin-bottom: 0;
  }
  
  .login-container{
    max-width: 450px;
    margin: auto;
    height: 100%;
  }

  .login-container-main{
    margin-top:10vh;
  }
}